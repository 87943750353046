<template>
  <div class="navbar-container d-flex content align-items-center">

    <!-- Nav Menu Toggler -->
    <ul class="nav navbar-nav d-xl-none mr-1">
      <li class="nav-item">
        <b-link
          class="nav-link"
          @click="toggleVerticalMenuActive"
        >
          <feather-icon
            icon="MenuIcon"
            size="21"
          />
        </b-link>
      </li>
    </ul>

    <!-- Left Col -->
    <template v-if="restaurant && !isChangingRestaurant">
      <h4
        v-if="restaurants.length === 1"
        class="mb-0"
      >
        {{ restaurant.name }}
      </h4>
      <b-dropdown
        v-else
        variant="flat-primary"
        class="dropdown-restaurant"
      >
        <template #button-content>
          {{ restaurant.name }} / {{ restaurant.organization.name }}
          <template v-if="restaurant.nickname">
            ({{ restaurant.nickname }})
          </template>
        </template>
        <b-dropdown-item
          v-for="restaurantItem in restaurants"
          :key="restaurantItem.id"
          :disabled="restaurantItem.id === restaurant.id"
          @click="onClickRestaurant(restaurantItem.id)"
        >
          {{ restaurantItem.name }} / {{ restaurantItem.organization.name }}
          <template v-if="restaurantItem.nickname">
            ({{ restaurantItem.nickname }})
          </template>
        </b-dropdown-item>
      </b-dropdown>
    </template>
    <b-spinner
      v-else
      variant="primary"
    />
    <!--<div class="bookmark-wrapper align-items-center d-none d-lg-flex">
      <dark-Toggler class="d-none d-lg-block" />
    </div>-->

    <!--<b-button
      variant="primary"
      class="ml-1"
      :to="{ name: 'plans' }"
    >
      View plans
    </b-button>-->

    <b-navbar-nav class="nav align-items-center ml-auto">
      <subscription-notification-link
        v-if="!isSubscriptionNotification"
        v-b-tooltip.hover.bottom="subscriptionNotificationText"
      />

      <locale class="mr-1" />

      <notification-dropdown />

      <b-nav-item-dropdown
        right
        toggle-class="d-flex align-items-center dropdown-user-link"
        class="dropdown-user ml-2"
      >
        <template #button-content>
          <!--<div class="d-sm-flex d-none user-nav">
            <p class="user-name font-weight-bolder mb-0">
              {{ name }}
            </p>
            <span class="user-status">Admin</span>
          </div>-->
          <b-avatar
            size="40"
            variant="light-primary"
            :text="initials"
          />
        </template>

        <!-- <b-dropdown-item link-class="d-flex align-items-center">
          <feather-icon
            size="16"
            icon="UserIcon"
            class="mr-50"
          />
          <span>Profile</span>
        </b-dropdown-item>

        <b-dropdown-item link-class="d-flex align-items-center">
          <feather-icon
            size="16"
            icon="MailIcon"
            class="mr-50"
          />
          <span>Inbox</span>
        </b-dropdown-item>

        <b-dropdown-item link-class="d-flex align-items-center">
          <feather-icon
            size="16"
            icon="CheckSquareIcon"
            class="mr-50"
          />
          <span>Task</span>
        </b-dropdown-item>

        <b-dropdown-item link-class="d-flex align-items-center">
          <feather-icon
            size="16"
            icon="MessageSquareIcon"
            class="mr-50"
          />
          <span>Chat</span>
        </b-dropdown-item> -->

        <b-dropdown-item
          :to="{ name: 'account-settings' }"
          link-class="d-flex align-items-center"
        >
          <feather-icon
            size="16"
            icon="UserIcon"
            class="mr-50"
          />
          <span>{{ $t('User Account') }}</span>
        </b-dropdown-item>

        <b-dropdown-divider />

        <b-dropdown-item
          link-class="d-flex align-items-center"
          @click="logout"
        >
          <feather-icon
            size="16"
            icon="LogOutIcon"
            class="mr-50"
          />
          <span>{{ $t('Logout') }}</span>
        </b-dropdown-item>
      </b-nav-item-dropdown>
    </b-navbar-nav>
  </div>
</template>

<script>
import {
  BLink,
  BNavbarNav,
  BNavItemDropdown,
  BDropdown,
  BDropdownItem,
  BDropdownDivider,
  BAvatar,
  BSpinner,
  // BButton,
  VBTooltip,
} from 'bootstrap-vue'
// import DarkToggler from '@core/layouts/components/app-navbar/components/DarkToggler.vue'
import { mapGetters, mapActions } from 'vuex'
// import { initialAbility } from '@/libs/acl/config'
import Locale from '@core/layouts/components/app-navbar/components/Locale.vue'
import SubscriptionNotificationLink from '@/components/SubscriptionNotificationLink.vue'
import mixinSubscriptionNotification from '@/mixins/subscription-notification'
import NotificationDropdown from '@core/layouts/components/app-navbar/components/NotificationDropdown.vue'

export default {
  components: {
    SubscriptionNotificationLink,
    BLink,
    BNavbarNav,
    BNavItemDropdown,
    BDropdown,
    BDropdownItem,
    BDropdownDivider,
    BAvatar,
    BSpinner,
    // BButton,

    Locale,
    NotificationDropdown,

    // Navbar Components
    // DarkToggler,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  mixins: [
    mixinSubscriptionNotification,
  ],
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      isChangingRestaurant: false,
    }
  },
  computed: {
    ...mapGetters({
      user: 'user/user',
      restaurant: 'restaurant/restaurant',
      isSubscriptionNotification: 'main/isSubscriptionNotification',
      restaurants: 'restaurant/restaurants',
    }),
    initials() {
      let result = ''

      if (this.user) {
        if (this.user.firstName) {
          result += this.user.firstName.charAt(0)
        }
        if (this.user.lastName) {
          result += this.user.lastName.charAt(0)
        }
      }

      return result
    },
  },
  methods: {
    ...mapActions({
      clearLocalStorageData: 'main/clearLocalStorageData',
      clearStoreData: 'main/clearStoreData',

      setCurrentRestaurantId: 'restaurant/setCurrentRestaurantId',
      clearStoreMainData: 'main/clearStoreMainData',
      clearBillingData: 'billing/clearBillingData',

      getSubscription: 'billing/getSubscription',
      getInvoices: 'billing/getInvoices',
      getBillingConfig: 'billing/getBillingConfig',

      getBadgesTagsAllData: 'badgesTags/getBadgesTagsAllData',
    }),
    async logout() {
      this.clearLocalStorageData()

      // Reset ability
      // this.$ability.update(initialAbility)

      // Redirect to login page
      await this.$router.push({ name: 'login' })

      this.clearStoreData()
    },
    async onClickRestaurant(id) {
      this.isChangingRestaurant = true

      if (this.$route.name !== 'dashboard') {
        await this.$router.push({ name: 'dashboard', params: { isFetchDisabledOnCreated: true } })
      }

      this.clearBillingData()
      this.clearStoreMainData()
      this.setCurrentRestaurantId(id)

      await Promise.all([
        this.getSubscription(),
        this.getInvoices(),
        this.getBillingConfig(),

        this.getBadgesTagsAllData(),
      ])

      this.isChangingRestaurant = false
    },
  },
}
</script>

<style lang="sass">
  .navbar-floating
    .navbar-container
      padding-left: 1.5rem
      padding-right: 1.5rem

  .dropdown-restaurant
    .btn-flat-primary
      &:focus
        background-color: transparent
</style>
